import React, { useState } from 'react'
import loadable from '@loadable/component'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import { createTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BTCicon from '../../assets/image/svg/btc.inline.svg'
import ETHicon from '../../assets/image/svg/eth.inline.svg'
import LTCicon from '../../assets/image/svg/ltc.inline.svg'
import BCHicon from '../../assets/image/svg/bch.inline.svg'
import WithTheme from '../../helpers/WithTheme'

const Paper = loadable(() => import('@mui/material/Paper'))
const Table = loadable(() => import('@mui/material/Table'))
const TableCell = loadable(() => import('@mui/material/TableCell'))
const TableRow = loadable(() => import('@mui/material/TableRow'))
const TableBody = loadable(() => import('@mui/material/TableBody'))
const TableHead = loadable(() => import('@mui/material/TableHead'))
const TableContainer = loadable(() => import('@mui/material/TableContainer'))

// Overrides for material-ui table

// const {t} = useTranslation();

// function createData(id, txFee, spread, totalCost ) {
//   return { id, txFee, spread, totalCost };
// }

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: 0,
    overflow: 'hidden',
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  table: {
    minWidth: 600,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const MktFeesTable = props => {
  const { t, ready } = useTranslation(['agreements'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const classes = useStyles()
  const [order] = useState('asc')
  const [orderBy] = useState('calories')
  const [page] = useState(0)
  const [rowsPerPage] = useState(13)

  const headCells = [
    {
      id: 'market',
      numeric: false,
      disablePadding: false,
      label: `${t('market')}`,
    },
    {
      id: 'crypto',
      numeric: false,
      disablePadding: false,
      label: `${t('crypto')}`,
    },
    {
      id: 'Quoted',
      numeric: false,
      disablePadding: false,
      label: `${t('Quoted')}`,
    },
    {
        id: 'min',
        numeric: false,
        disablePadding: false,
        label: `${t('min')}`,
      },
      {
        id: 'max',
        numeric: false,
        disablePadding: false,
        label: `${t('max')}`,
      },
      {
        id: 'tick',
        numeric: false,
        disablePadding: false,
        label: `${t('tick')}`,
      },
      {
        id: 'daily',
        numeric: false,
        disablePadding: false,
        label: `${t('daily')}`,
      },
      {
        id: 'tradeCost',
        numeric: false,
        disablePadding: false,
        label: `${t('tradeCost')}`,
      },
  ]

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              style={{ color: userDarkMode ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.56)' }}
              key={index}
              align="center"
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    // onRequestSort: PropTypes.func.isRequired,
    // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    // orderBy: PropTypes.string.isRequired,
  }

  let rows = [
    {
      market: 'BTC-USD',
      icon: <BTCicon className="mr-2" aria-hidden="true" />,
      crypto: 'Bitcoin',
      quoted: 'USD',
      min: '.0001 BTC',
      max: 'None',
      tick: '.00000001',
      daily: '1 BTC',
      tradeCost: '$1.50'
    },
    {
      market: 'ETH-USD',
      icon: <ETHicon className="mr-2" aria-hidden="true" />,
      crypto: 'Ethereum',
      quoted: 'USD',
      min: '.001 ETH',
      max: 'None',
      tick: '.00000001',
      daily: '20 ETH',
      tradeCost: '$1.50'
    },
    {
      market: 'LTC-USD',
      icon: <LTCicon className="mr-2" aria-hidden="true" />,
      crypto: 'Litecoin',
      quoted: 'USD',
      min: '.001 LTC',
      max: 'None',
      tick: '.00000001',
      daily: '100 LTC',
      tradeCost: '$1.50'
    },
    {
      market: 'BCH-USD',
      icon: <BCHicon className="mr-2" aria-hidden="true" />,
      crypto: 'Bitcoin Cash',
      quoted: 'USD',
      min: '.0001 BCH',
      max: 'None',
      tick: '.00000001',
      daily: '50 BCH',
      tradeCost: '$1.50'
    }
  ]

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return ready && (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="Pricing table"
          >
            <EnhancedTableHead classes={classes} />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell
                        style={{ color: userDarkMode ? '#FAFAFA' : 'black', width: '15%' }}
                        align="left"
                      >
                        {row.icon} {row.market}
                      </TableCell>
                      <TableCell
                        style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}
                        align="center"
                      >
                        {row.crypto}
                      </TableCell>
                      <TableCell
                        style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}
                        align="center"
                      >
                        {row.quoted}
                      </TableCell>
                      <TableCell
                        style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}
                        align="center"
                      >
                        {row.min}
                      </TableCell>
                      <TableCell
                        style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}
                        align="center"
                      >
                        {row.max}
                      </TableCell>
                      <TableCell
                        style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}
                        align="center"
                      >
                        {row.tick}
                      </TableCell>
                      <TableCell
                        style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}
                        align="center"
                      >
                        {row.daily}
                      </TableCell>
                      <TableCell
                        style={{ color: userDarkMode ? '#FAFAFA' : 'black' }}
                        align="center"
                      >
                        {row.tradeCost}
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

const muiTheme = ({ userDarkMode }) =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          body: {
            fontSize: '16px',
            fontFamily: 'CircularStd, sans-serif !important',
            fontWeight: 300,
            padding: '10px 16px',
          },
          head: {
            fontFamily: 'CircularStd, sans-serif !important',
            fontStyle: 'normal',
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '20px',
            textAlign: 'center',
            padding: '21px 8px',
          },
          root: { borderBottom: '1px solid rgba(137,137,137,0.16)' },
        },
      },
    },
    mixins: {},
    palette: {
      text: { hint: 'rgba(0, 0, 0, 0.38)' },
      mode: 'light',
      type: 'light',
    },
  })

export default WithTheme(muiTheme)(MktFeesTable)