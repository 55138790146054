import React from 'react'
import MetaData from '../components/MetaData'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import { Section, Title, Text } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MktFeesTable from '../components/Table/MktFeesTable'


const SupportingAgreements = () => {
  const { t, ready } = useTranslation(['agreements', 'privacy'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <>
      <MetaData {...{userDarkMode}} page="default" />
      <PageWrapper headerDark footerDark>
        <Section bg={userDarkMode ? 'black' : 'light'}>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="hero" color={userDarkMode ? 'light' : 'dark'}>
                  {t('heroTitle')}
                </Title>
                <ul className="ml-5">
                  <li>
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      1. {t('accountOpening')}
                    </Text>
                  </li>
                  <li>
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    2. {t('tradingRules')}
                    </Text>
                  </li>
                  <li>
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    3. {t('cryptoRisk')}
                    </Text>
                  </li>
                  <li>
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      4. {t('privacyPolicy')}
                    </Text>
                  </li>
                  <li>
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    5. {t('consent')}
                    </Text>
                  </li>
                  <li>
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    6. {t('pricing')}
                    </Text>
                  </li>
                  <li>
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    7. {t('markets')}
                    </Text>
                  </li>
                </ul>
                

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  1. {t('accountOpening')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('trader')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('providesAccess')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('completeActivation')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('settlement')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('tradeFees')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('adminFees')}
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('completeList')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('custodialAccount')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('custodianMaintains')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('duringCustodial')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('tradersShould')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('approvedTraderAcc')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('sendsMessages')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('sameEmail')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('allReqs')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('noCommission')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('allFees')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('accessed')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('reflects')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('placedBy')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('settlementsOfCrypto')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('erroneous')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('pool')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('tradingMarkets')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('linkedExchanges')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('responsible')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('closing')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('shouldContact')}
                </Text>










                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  2. {t('tradingRules')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('byAccessing')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('allOrders')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('markup')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('usedTo')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('features')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('certain')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('allowsTrader')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('placeAnOrder')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('whenAtrader')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('marketOrders')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('limitOrder')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('stopLimit')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('directMarket')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('notApply')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('commercially')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('selfDirected')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('submitsAnOrder')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('anyQuestions')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('regardingPlatform')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  3. {t('cryptoRisk')}
                </Title>
                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('knowledge')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('representation')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('purchasing')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('attempting')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('mayBeCancelled')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('risky')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  4. {t('privacyPolicy')}
                </Title>
                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('Overview')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('committed')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('personalInfo')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('controller')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('usingTheSites')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('concerns')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('howWeUse')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weUse')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('shouldYou')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('situations')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('youProvide')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('thisInfo')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('mayOrWill')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('usingServices')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('provisionOfServices')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('weMayUse')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('events')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('someCases')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('mayShare')}
                </Text>
                
                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('siteUseInfo')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('webServers')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('purposes')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('measure')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('administer')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('efforts')}
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('thirdParties')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('cookie')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('likeMost')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('firstAccess')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('acceptCookies')}
                </Text>
                
                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('whatAreCookies')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('cookiesAre')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('mayUseCookies')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('controlCookies')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('accessSites')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('withdrawn')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('follow')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
                        Google Chrome
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
                        Microsoft Internet Explorer
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                        Mozilla Firefox
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    <a target="_blank" href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                    Apple Safari
                    </a>
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.inAddition')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="http://www.aboutads.info/choices/">
                        http://www.aboutads.info/choices/
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="http://www.networkadvertising.org/choices/">
                        http://www.networkadvertising.org/choices/
                      </a>
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      <a target="_blank" href="http://youronlinechoices.com/">
                        http://youronlinechoices.com/
                      </a>
                    </Text>
                  </li>
                </ul>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.webBeacons')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.asWell')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.allowUs')}
                </Text>
                

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.changesToThis')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.amend')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.provisionsContained')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.pleaseCheck')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.encourage')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.contact')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('support')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.doNotTrack')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.recognizes')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.optingOut')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.ifYouHave')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.evenIf')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.disclosing')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.weWillNot')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('privacy.primeTrust')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('privacy.youRequest')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('privacy.weWillDisclose')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('privacy.whereYou')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('privacy.partOf')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('privacy.toComplyWith')}
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.weWillAlso')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.security')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.encryptInfo')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.retention')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.weWillStore')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.linksTo')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.ourSites')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.child')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.underAge')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.outside')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.ifYouAccess')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.california')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.forCaliResidents')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.toYourEmail')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.european')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.forEUResidents')}
                </Text>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('privacy.requestAccess')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('privacy.obtainWithout')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('privacy.requestThat')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('privacy.restrict')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('privacy.requestTransfer')}
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.whereYouBelieve')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.exercise')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.governingLaw')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.byChoosing')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.changesTo')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.weReserve')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  {t('privacy.contact')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('privacy.ifYou')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                  5. {t('consent')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('pleaseRead')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('byChecking')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('Definitions')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('yourConsent')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('hardware')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('youNeed')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('method')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('allCommunications')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('method')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('allCommunications')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('electronic')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('sinceYou')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('updatingContactInfo')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('notifyUs')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('paper')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('mayObtain')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('withdrawing')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('withoutCharge')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('ifYou')}
                </Text>


                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('yourAbility')}
                </Title>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                  {t('youAreStating')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    6. {t('fees')}
                </Title>
                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('feesForDirect')}
                </Title>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('noCommissions')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('makerTaker')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('platformFees')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('custodyFees')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('spreadMarkup')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('wire')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('networkFees')}
                    </Text>
                  </li>
                </ul>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    {t('linked')}
                </Title>
                <ul className="ml-5">
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                      {t('platformFee')}
                    </Text>
                  </li>
                  <li type="circle">
                    <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('tradeFee')}
                    </Text>
                  </li>
                </ul>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('lsCryptoFees')}
                </Text>
                <Text className="mb-3" color={userDarkMode ? '#FAFAFA' : 'black'} variant="small">
                    {t('debited')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black' } variant="card">
                    7. {t('mktSpecification')}
                </Title>
                
              </Col>
            </Row>
            <MktFeesTable />
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default SupportingAgreements
